const languageData = [
    {
        languageId: "english",
        locale: "en",
        name: "English",
        icon: "us",
    },
    {
        languageId: "french",
        locale: "fr",
        name: "French",
        icon: "fr",
        country_code: "FR",
    },
    {
        languageId: "china",
        locale: "zh",
        name: "Chinese",
        icon: "zh",
        country_code: "CN",
    },
    {
        languageId: "japan",
        locale: "ja",
        name: "Japanese",
        icon: "ja",
        country_code: "JP",
    },
    {
        languageId: "italian",
        locale: "it",
        name: "Italian",
        icon: "it",
        country_code: "IT",
    },
    {
        languageId: "spanish",
        locale: "es",
        name: "Spanish",
        icon: "es",
        country_code: "SP",
    },
    {
        languageId: "germany",
        locale: "de",
        name: "German",
        icon: "de",
        country_code: "DE",
    },
    {
        languageId: "hebrew",
        locale: "he",
        name: "Hebrew",
        icon: "he",
        country_code: "IL",
    },
];
export default languageData;
