import _ from "lodash";

import en from "../locales/en"
import ja from "../locales/ja"
import de from "../locales/de"
import es from "../locales/es"
import fr from "../locales/fr"
import he from "../locales/he"
import it from "../locales/it"
import zh from "../locales/zh"

export const languages = {
    en,
    ja,
    de,
    es,
    fr,
    he,
    it,
    zh
};

export const BASE_URL = "https://admin.hk.co/";
// export const BASE_URL = "https://admin.hk.co/";
export const BASE_URL_ATTACH = "https://attachment.hk.co/";
export const IGI = "https://igi.org/verify.php?r=***";
export const GIA = "https://www.gia.edu/report-check?reportno=***";

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getPath = (path) => {
    const p = path.filter(loc => loc);
    return _.last(p)
};

export const page = {
    path: "/search-result",
    component: "DiamondSearchGrid",
};

export const howKnow = {
    FACEBOOK: "Facebook",
    GOOGLE: "Google",
    INSTAGRAM: "Instagram",
    LINKEDIN: "LinkedIn",
    NEWS: "News",
    OTHER: "Other",
    QQ: "QQ",
    RAPNET: "RapNet",
    SALES_PERSON: "Sales Person",
    SELECT_REFERENCE: "Select Reference",
    THIRD_PARTY_REFERENCE: "Third Party Reference",
    TWITTER: "Twitter",
    VDB_APP: "VDB App",
    VISIT: "Visit",
    WALK_IN: "Walk In",
    WECHAT: "WeChat",
    WHATSAPP: "WhatsApp",
    HK_BLOG: "Blog",
    MARKET_DIAMONDS: "Market.Diamonds",
};
export const reference = [
    "Select Reference",
    "Sales Person",
    "Third Party Reference",
    "RapNet",
    "VDB App",
    "LinkedIn",
    "WhatsApp",
    "Facebook",
    "Instagram",
    "Google",
    "WeChat",
    "Twitter",
    "QQ",
    "News",
    "Visit",
    "Walk In",
    "Other",
    "HK Blog",
    "Market.Diamonds",
];

export const authorizedPages = [
    "diamond-search",
    "fancy-search",
    "quick-search",
    "search-result",
    "match-pair",
    "parcel-search",
    "parcel-list",
    "upcoming-diamonds,",
    "compare",
    "selected-stones",
    "dashboard",
    "my-cart",
    "my-watchlist",
    "my-reminder",
    "my-comment",
    "my-enquiry",
    "price-calculator",
    "my-account",
    "appointment-history",
    "my-demand",
    "my-saved-search",
    "change-password",
    "settings",
    "company-details",
    "address-book",
    "change-email-address",
    "invoice",
    "best-of-hk",
    "new-diamonds",
    "customer-care/:name",
    "matchpair-settings",
    "my-order",
    "recommended",
    "customer-care/appointment",
];

export const myAccount = {
    personalDetails: {
        key: "my-account",
        label: "Personal Details",
    },
    companyDetails: {
        key: "company-details",
        label: "Company Details",
    },
    // TnC: {
    //     key: 'terms-and-documentation',
    //     label: 'Terms and Documentation'
    // },
    settings: {
        key: "settings",
        label: "Settings",
    },
    changePswd: {
        key: "change-password",
        label: "Change Password",
    },
    matchpairsettings: {
        key: "matchpair-settings",
        label: "Match Pair settings",
    },
    bookAppointment: { key: "appointment", label: "Book Appointment" },
    appointmenthistory: {
        key: "appointment-history",
        label: "appointment history",
    },
    addressbook: {
        key: "address-book",
        label: "address book",
    },
};

export const footerStaticPages = {
    whyus: {
        key: "why-hk",
        label: "Why Us ?",
    },
    customertestimonial: {
        key: "customer-testimonials",
        label: "Customer Testimonials",
    },
    policy: {
        key: "policy",
        label: "Policy",
    },
    news: {
        key: "news-media",
        label: "News & Media",
    },
    csr: {
        key: "corporate-social-responsibility",
        label: "CSR",
    },
};

export const educational = {
    edu4cs: {
        key: "4cs",
        label: "4C’s",
    },
    educutandpolisheddiamonds: {
        key: "cut-and-polished-diamonds",
        label: "Cut and Polished Diamonds",
    },
    eduwholesalediamonds: {
        key: "wholesale-diamonds",
        label: "Wholesale Diamonds",
    },
    educertifieddiamonds: {
        key: "certified-diamonds",
        label: "Certified Diamonds",
    },
    eduloosediamonds: {
        key: "loose-diamonds",
        label: "Loose Diamonds",
    },
    edufancydiamonds: {
        key: "fancy-diamonds",
        label: "Fancy Diamonds",
    },
};

export const matchPairsettings = [
    {
        key: "clarityId",
        label: "Clarity",
    },
    {
        key: "colorId",
        label: "Color",
    },
    {
        key: "carat",
        label: "Carat",
    },
    {
        key: "length",
        label: "Length",
    },
    {
        key: "Width",
        label: "Width",
    },
    {
        key: "height",
        label: "Height",
    },
    {
        key: "cutId",
        label: "Cut",
    },
    {
        key: "polishId",
        label: "Polish",
    },

    {
        key: "symmetryId",
        label: "Symmetry",
    },
    {
        key: "fluorescenceId",
        label: "Fluorescence",
    },
    {
        key: "tablePer",
        label: "Table%",
    },
    {
        key: "totalDepth",
        label: "Total Depth",
    },
];

export const redirectURl = "https://my.hkimpex.com";

export const customerCare = {
    bookAppointment: { key: "appointment", label: "Book Appointment",href: `${redirectURl}/signin` },
    eventCalendar: { key: "calendar", label: "Event Calendar",href: `${redirectURl}/customer-care/calendar` },
    conciergeServices: {
        key: "concierge-services",
        label: "Concierge Services",
    },
    faqs: {
        key: "FAQs",
        label: "FAQs",
        // subtab: [
        //     { key: 'about-us', label: 'About Us' },
        //     { key: 'about-diamonds', label: 'About Diamonds' },
        //     { key: 'about-certification', label: 'About Certification' },
        //     { key: 'about-shipping', label: 'About Shipping' },
        //     { key: 'about-payment-terms', label: 'About Payment Terms' },
        //     { key: 'about-other-policies', label: 'About Other Policies' },
        // ],
    },
    contact: { key: "contact", label: "Contact",href: `${redirectURl}/contact` },
    // giaReportCheck: { key: "gIA-report-check", label: "GIA Report Check" },
};

export const columns = [
    { fieldname: "Daimond Image", value: "isImageExists" },
    { fieldname: "Packet No", value: "mfgPacketNo" },
    { fieldname: "Shape", value: "shapeIdName" },
    { fieldname: "Carat", value: "carat" },
    { fieldname: "Clarity", value: "clarityIdName" },
    { fieldname: "Color", value: "colorIdName" },
    { fieldname: "Color Shade", value: "colorShadeIdName" },
    { fieldname: "Rap%", value: "calcDiscount" },
    { fieldname: "Rap Rate", value: "marketingRapaport" },
    { fieldname: "Pr/ Ct", value: "calcPricePerCarat" },
    { fieldname: "Amount", value: "calcAmount" },
    { fieldname: "Total Depth %", value: "totalDepth" },
    { fieldname: "Table %", value: "tablePer" },
    { fieldname: "L/W Ratio", value: "ratio" },
    { fieldname: "Cut", value: "cutIdName" },
    { fieldname: "Polish", value: "polishIdName" },
    { fieldname: "Sym", value: "symmetryIdName" },
    { fieldname: "Flouresence", value: "fluorescenceIdName" },
    { fieldname: "FC", value: "isFancyColor" },
    { fieldname: "Measurment", value: "measurement" },
    { fieldname: "Lab", value: "labIdName" },
    { fieldname: "Black Table", value: "blackInclusionTableIdName" },
    { fieldname: "Black Crown", value: "blackInclusionCrownIdName" },
    { fieldname: "White Table", value: "whiteInclusionTableIdName" },
    { fieldname: "White Crown", value: "whiteInclusionCrownIdName" },
    { fieldname: "Milky", value: "milkeyIdName" },
    { fieldname: "Eye Clean", value: "eyeCleanIdName" },
    { fieldname: "Location", value: "locationIdName" },
    { fieldname: "Rough Origin", value: "roughOriginIdName" },
];

export const diamondParameters = [
    { key: "shapeId", name: "Shape", master: "SHAPE" },
    { key: "colorId", name: "Color", master: "COLOR" },
    { key: "clarityId", name: "Clarity", master: "CLARITY" },
    { key: "fluorescenceId", name: "Fluorescence", master: "FLUORESCENCE" },
    { key: "colorShadeId", name: "Color Shade", master: "SHADE" },
    { key: "labId", name: "Lab", master: "LAB" },
    { key: "cutId", name: "Cut", master: "CUT" },
    { key: "polishId", name: "Polish", master: "POLISH" },
    { key: "symmetryId", name: "Symmetry", master: "SYMMETRY" },
    { key: "locationId", name: "Location", master: "LOCATION" },
    { key: "fancyColorIntensity", name: "Intensity", master: "INTENSITY" },
    { key: "fancyColorOvertone", name: "Overtone", master: "OVERTONE" },
    {
        key: "blackInclusionTableId",
        name: "Black Table",
        master: "BLACK_INCLUSION",
    },
    { key: "openTableId", name: "Open Table", master: "OPEN_TABLE" },
    {
        key: "blackInclusionCrownId",
        name: "Black Crown",
        master: "BLACK_INCLUSION_CROWN",
    },
    { key: "openCrownId", name: "Open Crown", master: "OPEN_CROWN" },
    {
        key: "whiteInclusionTableId",
        name: "White Table",
        master: "WHITE_INCLUSION_TABLE",
    },
    { key: "openPavilionId", name: "Open Pavilion", master: "OPEN_PAVILION" },
    {
        key: "whiteInclusionCrownId",
        name: "White Crown",
        master: "WHITE_INCLUSION_CROWN",
    },
    { key: "eyeCleanId", name: "Eye Clean", master: "EYECLEAN" },
    { key: "milkeyId", name: "Milky", master: "MILKEY" },
    { key: "hAndAId", name: "Heart and Arrow", master: "H_AND_A" },
    { key: "roughOriginId", name: "Rough Origin", master: "ORIGIN" },
    { key: "marketingBack", name: "Rap Discount" },
    { key: "marketingPricePerCarat", name: "Price/Carat" },
    { key: "totalDepth", name: "Total Depth" },
    { key: "tablePer", name: "Table" },
    { key: "girdlePer", name: "Girdle" },
    { key: "pAngle", name: "Pavilion Angle" },
    { key: "cAngle", name: "Crown Angle" },
    { key: "diameterMin", name: "Diameter Min" },
    { key: "diameterMax", name: "Diameter Max" },
    { key: "or", name: "Carat" },
    { key: "ratio", name: "Ratio" },
    { key: "marketingIssueDate", name: "New Arrivals" },
    { key: "showSrNo", name: "Show ID" },
    { key: "keyToSymbol", name: "Key To Symbol", master: "KEY_TO_SYMBOLS" },
    { key: "fancyColor", name: "Fancy Color" },
];

export const advancefilterkey = [
    {
        key: "blackInclusionTableId",
        name: "BLACK_INCLUSION",
    },
    {
        key: "blackInclusionCrownId",
        name: "BLACK_INCLUSION_CROWN",
    },
    {
        key: "hAndAId",
        name: "H_AND_A",
    },
    {
        key: "milkeyId",
        name: "MILKEY",
    },
    {
        key: "openCrownId",
        name: "OPEN_CROWN",
    },
    {
        key: "roughOriginId",
        name: "ORIGIN",
    },
    {
        key: "eyeCleanId",
        name: "EYECLEAN",
    },
    {
        key: "openPavilionId",
        name: "OPEN_PAVILION",
    },
    {
        key: "openTableId",
        name: "OPEN_TABLE",
    },
    {
        key: "whiteInclusionCrownId",
        name: "WHITE_INCLUSION_CROWN",
    },
    {
        key: "whiteInclusionTableId",
        name: "WHITE_INCLUSION_TABLE",
    },
    {
        key: "keyToSymbol",
        name: "KEY_TO_SYMBOLS",
    },
];
export const basicfilterkey = [
    {
        key: "shapeId",
        name: "SHAPE",
    },
    {
        key: "colorId",
        name: "COLOR",
    },
    {
        key: "clarityId",
        name: "CLARITY",
    },
    {
        key: "fluorescenceId",
        name: "FLUORESCENCE",
    },
    {
        key: "colorShadeId",
        name: "SHADE",
    },
    {
        key: "labId",
        name: "LAB",
    },
    {
        key: "cutId",
        name: "CUT",
    },
    {
        key: "polishId",
        name: "POLISH",
    },
    {
        key: "symmetryId",
        name: "SYMMETRY",
    },
    {
        key: "locationId",
        name: "LOCATION",
    },
    {
        key: "fancyColorIntensityId",
        name: "INTENSITY",
    },
    {
        key: "fancyColorOvertoneId",
        name: "OVERTONE",
    },
];
export const advanceObj = [
    "marketingPricePerCarat",
    "totalDepth",
    "marketingBack",
    "tablePer",
    "girdlePer",
    "pAngle",
    "cAngle",
    "diameterMin",
    "diameterMax",
];
export const basicObj = ["ratio", "marketingIssueDate"];
export const notInUpcoming = [
    "calcAmount",
    "calcPricePerCarat",
    "marketingRapaport",
    "rapAvg",
    "calcDiscount",
];
export const floatKeys = [
    "trackDiscount",
    "calcAmount",
    "carat",
    "rapAvg",
    "pAngle",
    "pHeight",
    "cHeight",
    "calcPricePerCarat",
    "calcDiscount",
    "tablePer",
    "totalDepth",
    "cAngle",
    "ratio",
    // 'culetPer',
    "diameterMax",
    "diameterMin",
    "marketingAmount",
    "marketingPricePerCarat",
    // 'girdlePer',
    // 'height',
    // 'length',
    // 'mfgCarat',
    // 'width'
];

export const purchaseFloatKeys = [
    "marketingRapaport",
    "calcDiscount",
    "calcPricePerCarat",
    "calcAmount",
    "carat",
    "measurement",
    "tablePer",
    "totalDepth",
    "ratio",
];
export const sortBy = {
    shapeIdName: "shapeIdSequence",
    colorIdName: "colorIdSequence",
    clarityIdName: "clarityIdSequence",
    sizeIdName: "sizeIdSequence",
    carat: "carat",
    marketingRapaport: "marketingRapaport",
    marketingBack: "marketingBack",
};
export const roundKeys = ["marketingRapaport"];
export const columnActionPaths = [
    "search-result",
    "upcoming-diamonds",
    "new-diamonds",
    "best-of-hk",
    "match-pair",
    "recommended",
];
export const columnActionPages = [
    "my-comment",
    "my-cart",
    "my-watchlist",
    "my-enquiry",
    "my-reminder",
];
//'addedIn' actionKeys sortedStatic
export const actionKeys = ["Actions", "Details"];
export const OrderactionKeys = ["Actions", "Details"];
export const stoneIdKey = "stoneId";
export const labIdKey = "labIdName";
export const keyToSymbolKey = "keyToSymbolStr";
export const masterKeys = { clarityIdName: "CLARITY", colorIdName: "COLOR" };
export const tooltipKeys = [
    "reminderDate",
    "enquiry",
    "name",
    "remarks",
    "remark",
    "keyToSymbolStr",
    "measurement",
    "comments",
    "fancyColorDescription",
];
export const sortedStatic = [
    "Actions",
    "Details",
    "reminderDate",
    "enquiry",
    "name",
    "remarks",
    "remark",
];
export const trackTypeObj = {
    CART: 1,
    WATCHLIST: 2,
    REQUEST: 3,
    OFFER: 4,
    REMINDER: 5,
    ENQUIRY: 6,
    COMMENT: 99,
    SHIPMENT: 7,
    SHOWSELECT: 8,
};

export const pageDetails = {
    "search-result": "Search Result",
    "my-enquiry": "Enquiry",
    "match-pair": "Match Pair",
    invoice: "Invoice",
    "parcel-list": "Parcel Result",
    compare: "Compare Diamonds",
    "parcel-search": "Parcel Search",
    "diamond-details": "Diamond Details",
    "selected-stones": "Selected Stones",
    "my-reminder": "My Reminder",
    "my-comment": "My Comment",
    "my-watchlist": "WatchList",
    "my-cart": "Cart",
    "my-saved-search": "Saved Search",
    "diamond-search": "Search Page",
    "fancy-search": "Fancy Search",
    signin: "Login",
    signup: "Registration",
    "forgot-password": "Forget Password",
    "change-password": "Change Password",
    "reset-password": "Reset Password",
    settings: "Settings",
    "my-account": "My Account",
    "diamond-history": "Search History",
    dashboard: "Home Page",
    "/": "Home Page",
    "new-diamonds": "New Goods",
    "best-of-hk": "Best Hk Diamonds",
    "upcoming-diamonds": "Upcoming Goods",
    "my-demand": "My Demand",
    csr: "CSR",
    "hk-front": "Home Page",
    career: "Career",
    "our-story": "Our Story",
    "entity-brand": "Entity Brand",
    services: "Services",
    "HK-grading": "Grading",
    help: "Help",
    "hk-achievements": "HK Achivement",
    "terms-and-condition": "Terms And Condition",
    event: "Event",
    "csr-detail": "CSR Detail",
    "corporate-social-responsibility": "Corporate Social Responsibility",
    "guest-login": "Guest Login",
    calendar: "Calendar",
    recommended: `I'm Lucky`,
    "loose-diamonds": "Loose Diamonds",
    "4cs": "4c",
    feedback: "Feedback",
    sitemap: "Sitemap",
    presence: "Presence",
    "news-media": "HK News",
    "why-hk": "Why HK",
    policy: "Policy",
    "customer-testimonial": "Customer Testimonials",
    "cut-and-polished-diamonds": "Cut and Polished Diamonds",
    "wholesale-diamonds": "Wholesale Diamonds",
    "certified-diamonds": "Certified Diamonds",
    "fancy-diamonds": "Fancy Diamonds",
    "quick-search": "quick-search",
    "price-calculator": "Price Calculator",
    "appointment-history": "Appointment History",
    "company-details": "Company Details",
    "terms-and-documentation": "Terms and Documentation",
    "change-email-address": "Change Email Address",
    "customer-care": "Customer-Care",
    "hk-care": "HK Care",
    "matchpair-settings": "Match pair Settings",
    "my-order": "Order",
    FAQs: "FAQs",
    "concierge-services": "Concierge Services",
    appointment: "Appointment",
    contact: "Contact Us",
    "address-book": "Address Book",
};

export const fields = {
    firstName: "Firstname",
    lastName: "Lastname",
    name: "Name",
    email: "Email Address",
    emails: "Email Address",
    mobile: "Mobile No.",
    mobiles: "Mobile No.",
    countryCode: "Countrycode",
    username: "Username",
    password: "Password",
    cpassword: "Confirm Password",
    dob: "Birth Date",
    phone: "Telephone No.",
    companyName: "Company Name",
    designation: "Designation",
    BusinessType: "Business Type",
    country: "Country",
    state: "State",
    city: "City",
    pinCode: "Pin Code",
    address1: "Address",
    address2: "Address",
    fax: "Fax",
    howKnow: "How do you know us?",
    photoId: "Photo Identity Proof",
    anniversary: "Anniversary Date",
    businessId: "Business identity Proof",
    iAgree: "I Agree",
    howKnowName: "Source",
};

export const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "username",
    "password",
    "cpassword",
    "dob",
    "mobile",
    "companyName",
    "designation",
    "BusinessType",
    "country",
    "state",
    "city",
    //"pinCode",
    "address1",
    "howKnow",
    "howKnowName",
    //'photoId',
    "iAgree",
    //"fax",
];
export const requiredCompanyFields = [
    "companyName",
    "address",
    "landMark",
    //"zipCode",
    "businessType",
    "designation",
    "howKnow",
    "country",
    "state",
    "city",
    "faxes",
];
export const requiredaddressField = ["address", "zipCode", "country", "state", "city"];

export const requiredContactFields = ["name", "email", "mobile", "messege"];
export const contactFields = {
    name: "Name",
    email: "Email",
    mobile: "Mobile",
    messege: "Message",
};

export const companyFields = {
    companyName: "Company Name",
    address: "Address",
    landMark: "LandMark",
    zipCode: "ZipCode",
    businessType: "BusinessType",
    designation: "Designation",
    howKnow: "HowKnow",
    country: "Country",
    state: "State",
    city: "City",
    faxes: "Faxes",
};
export const addressField = {
    address: "Address",
    zipCode: "ZipCode",
    country: "Country",
    state: "State",
    city: "City",
};

export const changePswdFields = {
    currentPassword: "Current Password",
    newPassword: "New Password",
    repeatPassword: "Repeat Password",
};

export const requiredChangePswdFields = ["currentPassword", "newPassword", "repeatPassword"];
export const requiredResetPswdFields = ["confirmPassword", "newPassword"];
export const resetPswdFields = ["confirmPassword", "newPassword"];

export const fieldsrequiredGuest = ["name", "companyName", "mobile", "email"];
export const fieldsGuest = {
    name: "Name",
    companyName: "Company Name",
    countryCode: "Countrycode",
    mobile: "Contact No.",
    email: "Email",
};

export const BANNER_TYPES = {
    LOGIN_PAGE: 1,
    REGISTER_PAGE: 2,
    FORGOT_PASSWORD_PAGE: 3,
    GUEST_LOGIN: 4,
    CALENDAR: 5,
    DASHBOARD: 6,
};

export const quickSearch = [
    {
        allowColors: [
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
        ],
        TotalColorsGroups: ["DEF", "GHI", "J-", "FANCY COLOR"],
        TotalClarityGroups: ["IF", "VVS", "VS", "SI", "I", "OTHER"],
        colors: [
            {
                id: "D",
                name: "DEF",
                sequence: 1,
                innerSequence: 1,
            },
            {
                id: "E",
                name: "DEF",
                sequence: 1,
                innerSequence: 2,
            },
            {
                id: "F",
                name: "DEF",
                sequence: 1,
                innerSequence: 3,
            },
            {
                id: "G",
                name: "GHI",
                sequence: 2,
                innerSequence: 4,
            },
            {
                id: "H",
                name: "GHI",
                sequence: 2,
                innerSequence: 5,
            },
            {
                id: "I",
                name: "GHI",
                sequence: 2,
                innerSequence: 6,
            },
            {
                id: "J",
                name: "J-",
                sequence: 3,
                innerSequence: 7,
            },
            {
                id: "K",
                name: "J-",
                sequence: 3,
                innerSequence: 8,
            },
            {
                id: "L",
                name: "J-",
                sequence: 3,
                innerSequence: 9,
            },
            {
                id: "M",
                name: "J-",
                sequence: 4,
                innerSequence: 10,
            },
            {
                id: "N",
                name: "J-",
                sequence: 4,
                innerSequence: 11,
            },
            {
                id: "O",
                name: "J-",
                sequence: 4,
                innerSequence: 12,
            },
            {
                id: "P",
                name: "J-",
                sequence: 4,
                innerSequence: 13,
            },
            {
                id: "Q-R",
                name: "J-",
                sequence: 4,
                innerSequence: 14,
            },
            {
                id: "S-T",
                name: "J-",
                sequence: 4,
                innerSequence: 15,
            },
            {
                id: "U-V",
                name: "J-",
                sequence: 4,
                innerSequence: 16,
            },
            {
                id: "W-X",
                name: "J-",
                sequence: 4,
                innerSequence: 17,
            },
            {
                id: "Y-Z",
                name: "J-",
                sequence: 4,
                innerSequence: 18,
            },
        ],
        clarity: [
            {
                id: "FL",
                name: "IF",
                sequence: 1,
                innerSequence: 1,
            },
            {
                id: "IF",
                name: "IF",
                sequence: 1,
                innerSequence: 2,
            },
            {
                id: "VVS1",
                name: "VVS",
                sequence: 2,
                innerSequence: 3,
            },
            {
                id: "VVS2",
                name: "VVS",
                sequence: 2,
                innerSequence: 4,
            },
            {
                id: "VS1",
                name: "VS",
                sequence: 3,
                innerSequence: 5,
            },
            {
                id: "VS1-",
                name: "VS",
                sequence: 3,
                innerSequence: 6,
            },
            {
                id: "VS1+",
                name: "VS",
                sequence: 3,
                innerSequence: 7,
            },
            {
                id: "VS2",
                name: "VS",
                sequence: 3,
                innerSequence: 8,
            },
            {
                id: "VS2-",
                name: "VS",
                sequence: 3,
                innerSequence: 9,
            },
            {
                id: "VS2+",
                name: "VS",
                sequence: 3,
                innerSequence: 10,
            },
            {
                id: "SI1",
                name: "SI",
                sequence: 4,
                innerSequence: 11,
            },
            {
                id: "SI1-",
                name: "SI",
                sequence: 4,
                innerSequence: 12,
            },
            {
                id: "SI1+",
                name: "SI",
                sequence: 4,
                innerSequence: 13,
            },
            {
                id: "SI2",
                name: "SI",
                sequence: 4,
                innerSequence: 14,
            },
            {
                id: "SI2+",
                name: "SI",
                sequence: 4,
                innerSequence: 15,
            },
            {
                id: "SI2-",
                name: "SI",
                sequence: 4,
                innerSequence: 16,
            },
            {
                id: "SI3",
                name: "SI",
                sequence: 4,
                innerSequence: 17,
            },
            {
                id: "SI3-",
                name: "SI",
                sequence: 4,
                innerSequence: 18,
            },
            {
                id: "SI3+",
                name: "SI",
                sequence: 4,
                innerSequence: 19,
            },
            {
                id: "I1",
                name: "I",
                sequence: 5,
                innerSequence: 20,
            },
            {
                id: "I1-",
                name: "I",
                sequence: 5,
                innerSequence: 21,
            },
            {
                id: "I1+",
                name: "I",
                sequence: 5,
                innerSequence: 22,
            },
            {
                id: "I2",
                name: "I",
                sequence: 5,
                innerSequence: 23,
            },
            {
                id: "I2-",
                name: "I",
                sequence: 5,
                innerSequence: 24,
            },
            {
                id: "I2+",
                name: "I",
                sequence: 5,
                innerSequence: 25,
            },
            {
                id: "I3",
                name: "I",
                sequence: 5,
                innerSequence: 26,
            },
            {
                id: "I3-",
                name: "I",
                sequence: 5,
                innerSequence: 27,
            },
            {
                id: "I3+",
                name: "I",
                sequence: 5,
                innerSequence: 28,
            },
        ],
        width: "",
    },
];

export const MonthName = {
    JAN: "JAN",
    FEB: "FEB",
    MAR: "MAR",
    APR: "APR",
    MAY: "MAY",
    JUNE: "JUNE",
    JULY: "JULY",
    AUG: "AUG",
    SEP: "SEP",
    OCT: "OCT",
    NOV: "NOV",
    DEC: "DEC",
    INFO: "INFO",
    SHOW: "SHOW",
};
export const RapPer = [-99, -80, -60, -40, -20, 0, 20, 40, 60, 80, 99];

/*
let labMaster = localStorage && localStorage.getItem("hkw-master");
let labGIA =
    labMaster && JSON.parse(labMaster) && JSON.parse(labMaster).LAB.find((x) => x.code === "GIA");
export const Inventory = [
    {
        carat: {
            ">=": 0.18,
            "<=": 0.959,
        },
        lab: labGIA && labGIA.id,
    },
    {
        carat: {
            ">=": 0.96,
            // '<=': 100
        },
        lab: labGIA && labGIA.id,
    },
];
*/

export const CALENDER_FIELD = [
    {
        action: "JANUARY",
        label: "JAN",
    },
    {
        action: "FEBRUARY",
        label: "FEB",
    },
    {
        action: "MARCH",
        label: "MAR",
    },
    {
        action: "APRIL",
        label: "APR",
    },
    {
        action: "MAY",
        label: "MAY",
    },
    {
        action: "JUNE",
        label: "JUN",
    },
    {
        action: "JULY",
        label: "JUL",
    },
    {
        action: "AUGUST",
        label: "AUG",
    },
    {
        action: "SEPTEMBER",
        label: "SEP",
    },
    {
        action: "OCTOBER",
        label: "OCT",
    },
    {
        action: "NOVEMBER",
        label: "NOV",
    },
    {
        action: "DECEMBER",
        label: "DEC",
    },
    {
        action: "SHOW",
        label: "SHOW",
    },
    {
        action: "INFO",
        label: "INFO",
    },
];

export const DiamondAllotingDate = "April 5,2018";
export const DiamondAssesingDate = "April 4,2018";
export const DiamondGradingDate = "May 22,2018";
export const DiamondOriginDate = "March 21,2018";
export const DiamondPlanningDate = "April 10,2018";
export const DiamondPolishingDate = "April 27,2018";
export const DiamondQualityDate = "May 3,2018";
export const DiamondLaserDate = "April 10,2018";

/*export const storeSessionUUID = () => {
    var getSessionUUID = localStorage.getItem("hkw-uuid");
    if (!getSessionUUID || !getSessionUUID.length) {
        localStorage.setItem("hkw-uuid", new Date().getTime());
    }
};*/

// storeSessionUUID();
/*
export const commonLogout = () => {
    localStorage.removeItem("hkw-token");
    localStorage.removeItem("hkw-master");
    localStorage.removeItem("hkw-column");
    localStorage.removeItem("hkw-keywords");
    localStorage.removeItem("hkw-userPermissions");
    localStorage.removeItem("Hk-Cart-Counter");
    const tokens = JSON.parse(localStorage.getItem("hkw-token"));
    if (!tokens) {
        window.location.href = window.location.origin + "/signin";
    }
};*/

export const DASHBOARD_TOOLTIP = [
    {
        element: ".user",
        intro: "My Portal",
    },
    {
        element: ".lang-steps",
        intro: "Language DropDown",
    },
    {
        element: ".calculator-tooltip",
        intro: "Calculator",
    },
    {
        element: ".notification-tooltip",
        intro: "View Notification Here",
    },
    {
        element: ".cart-tooltip",
        intro: "Your Cart",
    },
    {
        element: ".message-tooltip",
        intro: "Chat With Us",
    },
    // {
    //     element: ".floating-btn",
    //     intro: "Quick Search Here",
    //     position: "left"
    // },
    {
        element: ".sidebar-steps",
        intro: "Discover Menu",
    },

    // {
    //     element: "#icon_click0",
    //     intro: "View Dashboard"
    // },
    // {
    //     element: "#icon_click1",
    //     intro: "Search Diamonds Here"
    // },
    // {
    //     element: "#icon_click2",
    //     intro: "Fancy Diamonds Search"
    // },
    // {
    //     element: "#icon_click3",
    //     intro: "Recommended"
    // },
    // {
    //     element: "#icon_click4",
    //     intro: "Parcel Diamonds Search"
    // },
    // {
    //     element: "#icon_click5",
    //     intro: "New Diamonds"
    // },
    // {
    //     element: "#icon_click6",
    //     intro: "Best Of Hk Diamonds"
    // },
    // {
    //     element: "#icon_click7",
    //     intro: "Upcoming Diamonds"
    // },
    {
        element: ".search-voice ",
        intro: "Search Diamonds Directly",
    },
];

export const DETAIL_PAGE_TOOLTIP_STEPS = [
    {
        element: ".packetNo-tooltip",
        intro: "Diamond Packet-No",
    },
    {
        element: ".verify-tooltip",
        intro: "Verify Certificate",
    },
    {
        element: ".x-ray-tooltip",
        intro: "Share Diamond X-ray",
    },
    {
        element: ".whatsapp-tooltip",
        intro: "Share On Whatsapp",
    },
    {
        element: ".send-x-ray-tooltip",
        intro: "Copy Detail Link",
    }
];

export const SEARCH_RESULT_STEPS = [
    {
        element: ".add-to-cart",
        intro: "Add To Cart",
    },

    {
        element: ".add-watchList",
        intro: "Add To Watchlist",
    },
    {
        element: ".compare",
        intro: "Compare Diamonds",
    },
    {
        element: ".showSelect",
        intro: "Show Selected Diamonds",
    },
    {
        element: ".comment",
        intro: "Comment",
    },
    {
        element: ".reminder",
        intro: "Reminder",
    },
    {
        element: ".export-tooltip",
        intro: "You Can Print, Download And Email Listed Diamond Excel",
    },
    {
        element: ".filter-tooltip",
        intro: "You Can Apply Filter",
    },
    {
        element: ".setting-tooltip",
        intro: "You Can Change Column Settings",
    },
];

export const DIAMOND_SEARCH_STEPS = [
    {
        element: ".all-shape",
        intro: "Select All Criteria",
    },
    // {
    //     element: ".carat-range",
    //     intro: "Select Carat Range"
    // },
    // {
    //     element: ".color",
    //     intro: "Select Color"
    // },
    // {
    //     element: ".clarityId",
    //     intro: "Select Clarity"
    // },
    // {
    //     element: ".fluorescenceId",
    //     intro: "Select Fluorescence"
    // },
    // {
    //     element: ".colorShadeId",
    //     intro: "Select Color"
    // },
    // {
    //     element: ".labId",
    //     intro: "Select Lab"
    // },
    // {
    //     element: ".cutId",
    //     intro: "Select Cut"
    // },
    // {
    //     element: ".polishId",
    //     intro: "Select Polish"
    // },
    // {
    //     element: ".symmetryId",
    //     intro: "Select Symmetry"
    // },
    // {
    //     element: ".locationId",
    //     intro: "Select Location"
    // },
    // {
    //     element: ".arrival-tooltip",
    //     intro: "Select Arrivals"
    // },
    // {
    //     element: ".packetNo-tooltip",
    //     intro: "Select Packet No./ Certi No./ Show ID"
    // }
    {
        element: ".advance-tooltip",
        intro: "Advance Search",
    },
    {
        element: ".search-tooltip",
        intro: "Search Diamond",
    },
    {
        element: ".add-more-tooltip",
        intro: "Add More Search",
    },
    {
        element: ".save-tooltip",
        intro: "You Can Save Your Search",
    },
    {
        element: ".demand-tooltip",
        intro: "You Can Add Your Demand Diamond Search",
    },
    {
        element: ".match-pair-tooltip",
        intro: "Match Pair",
    },
    {
        element: ".create-excel-tooltip",
        intro: "You Can Create Excel",
    },
    {
        element: ".import-excel-tooltip",
        intro: "You Can Import Excel",
    },
    {
        element: ".sample-excel-tooltip",
        intro: "Download Sample",
    },
    {
        element: ".reset-filter-tooltip",
        intro: "Reset Your Filter",
    },
];

export const SIZEEX = [
    {
        name: "sizeEx",
        value: "3EX",
    },
    {
        name: "sizeEx",
        value: "2EX",
    },
    {
        name: "sizeEx",
        value: "2EX",
    },
    {
        name: "sizeEx",
        value: "NO BGM",
    },
];
export const MOBILE_VIEW_ACTION_BTN = [
    {
        intelMsg: "addWatchlist",
        icon: "add-watchList",
    },
    {
        intelMsg: "compare",
        icon: "compare",
    },
    {
        intelMsg: "comment",
        icon: "comment",
    },
    {
        intelMsg: "reminder",
        icon: "reminder",
    },
    {
        intelMsg: "showSelect",
        icon: "showSelect",
    },
    {
        intelMsg: "Print",
        icon: "print",
    },
    {
        intelMsg: "Download-Excel",
        icon: "downloadExcel",
    },
    {
        intelMsg: "Email-Excel",
        icon: "emailExcel",
    },
];

export const MIME_TYPES = {
    'png': ['89504e47'],
    'jpeg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
    'jpg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffee', 'ffd8ffdb'],
    'pdf': ['25504446'],
    xlsx: ['504b0304', '504b34', '504b0506', '504b0708'],
    docx: ['504b0304', '504b34', '504b0506', '504b0708'],
    ods: ['504b0304', '504b34', '504b0506', '504b0708'],
    xls: ['d0cf11e0'],
    doc: ['d0cf11e0'],
    csv: ['rfc4180', '5372204e'],
    mp4: ['00020']
}