import React, {Component} from "react";
import {connect} from "react-redux";
import {switchLanguage} from "../../Redux/Actions/Localize"
import languageData from "../../constants/languageData";
import {Select} from "antd";
import ipapi from "ipapi.co";
import _ from "lodash";

const { Option } = Select;

const LANG_COOKIE = "hk_lang"

class LangDropdown extends Component {
    state = {
        country_code: "",
        langDefaultVal:
            (this.props.commonData.locale &&
                this.props.commonData.locale.name) ||
            languageData[0].name,
    };

    componentDidMount() {

        function setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function getCookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        let getLang = getCookie(LANG_COOKIE);

        if (getLang === "") {
            let self = this;
            var callback = function (res) {

                
                if (res && res.country_code) {
                    let findCountry = _.find(languageData, { country_code: res.country_code });
                    let setLanguage = {
                        country_code: res.country_code,
                    };

                   
                    if (findCountry && findCountry.languageId) {
                        setCookie(LANG_COOKIE, findCountry.languageId, 1)

                        setLanguage.langDefaultVal = findCountry.name;
                        self.props.switchLanguage(findCountry.languageId);
                    }
                    self.setState(setLanguage);
                }
            };

            ipapi.location(callback);
        }
    }
    render() {
        const languageName = {
            en: "English",
            ko: "한국어",
            de: "Deutsch",
        }
        return (
            <Select
                className="lang-steps"
                defaultValue={this.state.langDefaultVal}
                style={{ width: 120 }}
                onChange={this.props.switchLanguage}
            >
                {languageData.map((language) => (
                    <Option
                        key={language.locale}
                        value={language.languageId}
                    >
                        {language.name}
                    </Option>
                ))}
            </Select>
        );
    }
}

const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, { switchLanguage })(LangDropdown);
