import languageData from "../../constants/languageData";
import _ from "lodash"
import {SWITCH_LANGUAGE} from "../../constants/ActionTypes";
export function switchLanguage(language) {
    let locale = _.find(languageData, { languageId: language });
    if(localStorage) {
        localStorage.setItem('hk-localisation', JSON.stringify(locale));
    }
    return {
        type: SWITCH_LANGUAGE,
        payload: locale
    };
}